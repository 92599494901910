import React, { useContext, useEffect, useState } from "react";

import MomentUtils from "@date-io/moment";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { isEmpty } from "lodash";
import * as moment from "moment";
import styled from "styled-components";
import Swal from "sweetalert2";
import { StringParam, useQueryParam } from "use-query-params";

import DashboardGrid from "~/components/Appointments/DashboardGrid";
import PrivateRoute from "~/components/Authentication/PrivateRoute";
import Flex from "~/components/Containers/Flex";
import LoadingError from "~/components/Loaders/LoadingError";
import { FiltersContext } from "~/components/Stores/FilterStore";
import Italic from "~/components/Typography/Italic";
import api from "~/utils/api/api";
import alliancesService from "~/utils/api/v1/alliancesService";
import appointmentService from "~/utils/api/v1/appointmentService";
import nurseService from "~/utils/api/v1/nurseService";
import * as estadoMunicipio from "~/utils/comunas/estado_municipio.json";
import * as regionComuna from "~/utils/comunas/region_comunas.json";
import { translateRegion } from "~/utils/comunas/regionTranslator";
import { needsAssistanceStatuses } from "~/utils/constants/filters";
import { COUNTRIES } from "~/utils/data/constants";
import Appointment from "~/utils/interfaces/Appointment";
import { NurseName } from "~/utils/interfaces/Nurse";

const Header = styled.div`
  max-width: 1400px;
  padding: 0 3rem 2rem 3rem;
  margin: 0 auto;
`;

const PrimaryButton = styled(Button)`
  max-height: 2.5rem;
  border-color: var(--primary);
  color: var(--primary);
`;

const Spaced = styled.div`
  margin: 0.5rem 1rem;
`;

const CommuneSelect = styled(Select)`
  width: 200px;
  margin-bottom: 1rem;
`;

const CountrySelect = styled(Select)`
  width: 200px;
  margin-bottom: 1rem;
`;

const RegionSelect = styled(Select)`
  width: 250px;
  margin-bottom: 1rem;
`;

const ExamTypeSelect = styled(Select)`
  width: 175px;
`;

const PaymentStatusSelect = styled(Select)`
  width: 175px;
`;

moment.locale("es");

interface AppointmentRequest {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: Appointment[];
  };
}

const locationSelectProps: Partial<MenuPropsType> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

function IndexPage(): JSX.Element {
  const [error, setError] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingNurses, setLoadingNurses] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<Array<Appointment>>([]);
  const [activeNurses, setActiveNurses] = useState<NurseName[]>([]);
  const [sms, setSms] = useState<boolean>(true);
  const [fast, setFast] = useState<boolean>(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [salesSourcesList, setSalesSourcesList] = useState<string[]>([]);
  const [excludedSalesSources, setExcludedSalesSources] = useState<string[]>([]);

  const [filters, filtersDispatch] = useContext(FiltersContext);

  const fetchAppointments = async (): Promise<void> => {
    setLoading(true);
    try {
      const request: AppointmentRequest = await api.request({
        method: "get",
        url: "dashboard/appointments/receipts_list/",
        params: {
          begin_date: filters.initDate.format("YYYY-MM-DD"),
          end_date: filters.finishDate.format("YYYY-MM-DD"),
          nurse: filters.nurse,
          booked: filters.booked,
          contacted: filters.contacted,
          arrived: filters.arrived,
          visited: filters.visited,
          "receipt-sent": filters.receipt,
          "results-sent": filters.results,
          sales_source: filters.salesSource,
          excluded_sources: excludedSalesSources.join(","),
          country: filters.country,
          comuna: filters.comuna,
          region: translateRegion(filters.region),
          exam_type: filters.exam_type,
          is_operative: filters.is_operative,
          payment_status: filters.payment_status,
          finished: filters.finished,
          needs_assistance_status: filters.needs_assistance_status,
        },
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const fetchAppointmentsBySearch = async () => {
    setLoading(true);
    try {
      const req = await appointmentService.searchAppointments(search || "");
      setAppointments(req.data.results);
      setTotalRows(req.data.count);
      setNextRequest(req.data.next.replace("http://", "https://"));
      setPrevRequest(req.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const fetchActiveNurses = async () => {
    setLoadingNurses(true);
    try {
      const req = await nurseService.fetchActiveNurses();
      setActiveNurses(req.data.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoadingNurses(false);
  };

  const handleFilterButton = (): void => {
    fetchAppointments();
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    setLoading(true);
    try {
      const request = await api.request({
        method: "GET",
        url: action === "next" ? nextRequest : prevRequest,
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  const createFilter = (value: string, actionType: string, tag: string): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>{tag}</InputLabel>
          <Select
            value={value}
            onChange={(e) => {
              filtersDispatch({
                type: actionType,
                payload: e.target.value as string,
              });
            }}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            <MenuItem value="yes">Listo</MenuItem>
            <MenuItem value="no">Pendiente</MenuItem>
          </Select>
        </FormControl>
      </Spaced>
    );
  };

  function createCommuneFilter(): JSX.Element {
    const regiones = Object.keys(regionComuna);
    let comunas = regionComuna[filters?.region === "Todas" ? regiones[0] : filters?.region];
    if (filters?.country === "México") {
      comunas = estadoMunicipio[filters?.region];
    }
    const label = filters?.country === "Chile" ? "Comuna" : "Municipio";

    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>{label}</InputLabel>
          <CommuneSelect
            autoWidth
            value={filters?.comuna}
            disabled={filters?.region === "Todas"}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_COMMUNE",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            <MenuItem value="Todas">Todas</MenuItem>
            {comunas?.map((commune: string) => (
              <MenuItem
                value={commune}
                key={commune}
              >
                {commune}
              </MenuItem>
            ))}
          </CommuneSelect>
        </FormControl>
      </Spaced>
    );
  }

  const createCountryFilter = (): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>País</InputLabel>
          <CountrySelect
            autoWidth
            value={filters?.country}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_COUNTRY",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            {COUNTRIES.map((country: string) => (
              <MenuItem
                value={country}
                key={country}
              >
                {country}
              </MenuItem>
            ))}
          </CountrySelect>
        </FormControl>
      </Spaced>
    );
  };

  const createRegionFilter = (): JSX.Element => {
    let regions: string[] = [];
    if (filters?.country === "Chile") {
      regions = Object.keys(regionComuna).slice(0, -1); // Remove default key
    } else if (filters?.country === "México") {
      regions = Object.keys(estadoMunicipio).slice(0, -1);
    }
    const label = filters?.country === "Chile" ? "Región" : "Estado";
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>{label}</InputLabel>
          <RegionSelect
            autoWidth
            value={filters?.region}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_REGION",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            <MenuItem value="Todas">Todas</MenuItem>
            {regions.map((region: string) => (
              <MenuItem
                value={region}
                key={region}
              >
                {translateRegion(region)}
              </MenuItem>
            ))}
          </RegionSelect>
        </FormControl>
      </Spaced>
    );
  };

  const createSalesSourceFilter = () => {
    return filters ? (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel> Origen </InputLabel>
          <Select
            value={filters.salesSource}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_SALESOURCE",
                payload: e.target.value as string,
              });
            }}
          >
            {salesSourcesList.map((salesSource) => (
              <MenuItem
                value={salesSource}
                key={salesSource}
              >
                {salesSource}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Spaced>
    ) : undefined;
  };

  const createExcludeSalesSourceFilter = () => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel> Excluir Origen </InputLabel>
          <Select
            value={excludedSalesSources}
            onChange={(e) => {
              setExcludedSalesSources(e.target.value as string[]);
            }}
            multiple={true}
          >
            {salesSourcesList.map((salesSource) => (
              <MenuItem
                value={salesSource}
                key={salesSource}
              >
                {salesSource}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Spaced>
    );
  };

  const createAppointmentStatusFilter = (value: string, actionType: string) => {
    return (
      <div className="w-40">
        <FormControl
          margin="normal"
          fullWidth
        >
          <InputLabel> Estado asistencia HT </InputLabel>
          <Select
            value={value}
            onChange={(e) => {
              filtersDispatch({
                type: actionType,
                payload: e.target.value as string,
              });
            }}
            autoWidth
          >
            {needsAssistanceStatuses.map((status) => (
              <MenuItem
                value={status.value}
                key={status.value}
              >
                {status.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const displayNurseFilter = (): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>Tomador</InputLabel>
          <Select
            disabled={loadingNurses}
            value={filters?.nurse}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_NURSE",
                payload: e.target.value as string,
              });
            }}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            {activeNurses.map((item: NurseName) => {
              return (
                <MenuItem
                  value={item.id}
                  key={item.id}
                >
                  {item.names} {item.last_names}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Spaced>
    );
  };

  const createExamTypeFilter = (): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <TextField
            fullWidth
            label="Exámenes"
            variant="standard"
            value={filters?.exam_type}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_EXAM_TYPE",
                payload: e.target.value as string,
              });
            }}
          />
        </FormControl>
      </Spaced>
    );
  };

  const createPaymentStautsFilter = (): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>Estado de pago</InputLabel>
          <PaymentStatusSelect
            autoWidth
            value={filters?.payment_status}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_PAYMENT_STATUS",
                payload: e.target.value as string,
              });
            }}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            <MenuItem value="Payment Pending">Pendiente</MenuItem>
            <MenuItem value="Invoice Sent">Factura Enviada</MenuItem>
            <MenuItem value="Payment Successful">Pagado</MenuItem>
          </PaymentStatusSelect>
        </FormControl>
      </Spaced>
    );
  };

  const getSalesSources = async (): Promise<any> => {
    try {
      const res = await appointmentService.fetchSalesSources();
      const salesSourceList = res.data.data;
      const clientsRes = await alliancesService.fetchClients();
      const clients = clientsRes.data;
      const clientsSalesSource = clients.map((client) => client.sales_source);
      const mergedSaleSources = new Set<string>(salesSourceList.concat(clientsSalesSource));
      const sortedMergedSaleSources = Array.from(mergedSaleSources).sort((a, b) => {
        return a.localeCompare(b);
      });
      setSalesSourcesList(sortedMergedSaleSources);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al eliminar la cita del dashboard de totalpack",
        allowOutsideClick: false,
      });
    }
  };

  useEffect((): void => {
    if (!search) {
      fetchAppointments();
      fetchActiveNurses();
      getSalesSources();
    }
  }, []);

  useEffect(() => {
    if (search) {
      fetchAppointmentsBySearch();
    }
  }, [search]);

  return (
    <PrivateRoute>
      <LoadingError
        error={error}
        loading={loading}
      />
      {!loading && isEmpty(error) && (
        <>
          <Header>
            <Flex
              justify="center"
              align="center"
            >
              <Grid
                item
                xs={2}
                style={{ textAlign: "right" }}
              >
                <Typography>Agenda Normal</Typography>
              </Grid>
              <Grid
                item
                xs={1}
                style={{ textAlign: "center" }}
              >
                <Switch
                  color="primary"
                  checked={filters?.is_operative}
                  onChange={(e) =>
                    filtersDispatch({
                      type: "UPDATE_IS_OPERATIVE",
                      payload: e.target.checked,
                    })
                  }
                />
              </Grid>
              <Grid
                item
                xs={2}
              >
                <Typography>Operativo</Typography>
              </Grid>
            </Flex>
            <Flex
              direction="column"
              align="center"
            >
              <h1>
                Estado de <Italic>{filters?.is_operative ? "Operativos" : "Appointments"}</Italic>
              </h1>
              <Flex
                align="center"
                justify="center"
                wrap="wrap"
              >
                <Spaced>
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale="es"
                  >
                    <KeyboardDatePicker
                      variant="inline"
                      format="LL"
                      margin="normal"
                      label="Fecha inicio"
                      value={filters?.initDate}
                      autoOk
                      onChange={(date: moment.Moment) => {
                        filtersDispatch({
                          type: "UPDATE_INITDATE",
                          payload: date,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Spaced>
                <Spaced>
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale="es"
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="LL"
                      margin="normal"
                      label="Fecha término"
                      value={filters?.finishDate}
                      autoOk
                      onChange={(date: moment.Moment) => {
                        filtersDispatch({
                          type: "UPDATE_FINISHDATE",
                          payload: date,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Spaced>
                {!filters?.is_operative && (
                  <>
                    {displayNurseFilter()}
                    {createFilter(filters?.booked, "UPDATE_BOOKED", "Agendado")}
                    {createFilter(filters?.contacted, "UPDATE_CONTACTED", "Contactado")}
                    {createFilter(filters?.arrived, "UPDATE_ARRIVED", "En domicilio")}
                    {createFilter(filters?.visited, "UPDATE_VISITED", "Visitado")}
                    {createFilter(filters?.receipt, "UPDATE_RECEIPT", "Boleta")}
                    {createFilter(filters?.results, "UPDATE_RESULTS", "Resultados")}
                    {createSalesSourceFilter()}
                    {createExcludeSalesSourceFilter()}
                    {createExamTypeFilter()}
                    {createFilter(filters?.finished, "UPDATE_FINISHED", "Finalizado")}
                    {createAppointmentStatusFilter(filters?.needs_assistance_status, "UPDATE_NEEDS_ASSISTANCE_STATUS")}
                  </>
                )}
                {filters?.is_operative && <>{createPaymentStautsFilter()}</>}
              </Flex>
              <Flex
                align="center"
                justify="center"
                wrap="wrap"
              >
                {createCountryFilter()}
                {createRegionFilter()}
                {createCommuneFilter()}
                <Spaced>
                  <PrimaryButton
                    variant="outlined"
                    color="primary"
                    onClick={handleFilterButton}
                  >
                    Filtrar
                  </PrimaryButton>
                </Spaced>
              </Flex>
              <Flex justify="space-around">
                <FormControlLabel
                  label="Ayuno"
                  control={
                    <Switch
                      color="primary"
                      checked={fast}
                      onChange={(e) => {
                        setFast(e.target.checked);
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label="SMS"
                  control={
                    <Switch
                      color="primary"
                      checked={sms}
                      onChange={(e) => {
                        setSms(e.target.checked);
                      }}
                    />
                  }
                />
              </Flex>
            </Flex>
          </Header>
          <DashboardGrid
            appointments={appointments}
            sms={sms}
            fasting={fast}
            totalRows={totalRows}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </PrivateRoute>
  );
}

export default IndexPage;
